import Enum from './enum'

/**
 * 連絡先種別
 */
class ContactType extends Enum {
    static PRIMARY = 1; // 主担当
    static SECONDARY = 2; // サブ担当
    static RETIRED = 3; // 過去担当

    static values() {
        return {
            [this.PRIMARY]: '主担当',
            [this.SECONDARY]: 'サブ担当',
            [this.RETIRED]: '過去担当',
        }
    }
}

export default ContactType;
