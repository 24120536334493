/**
 * 重複アラートトラン
 */
import { isBlank } from '@/utilities/typing';
import DuplicateStatus from '@/models/enums/duplicate-status';
import User from '@/models/entities/user';
import Project from '@/models/entities/project';

class Duplicate {
    duplicate_id;
    first_project;
    first_project_id;
    second_project;
    second_project_id;
    duplicate_status;
    determined_user;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.duplicate_id = properties.duplicate_id;
        this.first_project = new Project(properties.first_project);
        this.first_project_id = properties.first_project_id;
        this.second_project = new Project(properties.second_project);
        this.second_project_id = properties.second_project_id;
        this.duplicate_status = properties.duplicate_status ?? DuplicateStatus.UNTREATED;
        this.determined_user = new User(properties.determined_user);
    }
}

export default Duplicate;
