import Enum from './enum'

/**
 * 他社落ち理由
 */
class LostReason extends Enum {
    static LOST_REASON_1 = 1; // 価格
    static LOST_REASON_2 = 2; // サービス内容
    static LOST_REASON_3 = 3; // 営業対応
    static LOST_REASON_4 = 4; // 不信感
    static LOST_REASON_5 = 5; // 繋がり
    static LOST_REASON_6 = 6; // 総合的判断
    static LOST_REASON_9 = 9; // 不明

    static values() {
        return {
            [this.LOST_REASON_1]: '価格',
            [this.LOST_REASON_2]: 'サービス内容',
            [this.LOST_REASON_3]: '営業対応',
            [this.LOST_REASON_4]: '不信感',
            [this.LOST_REASON_5]: '繋がり',
            [this.LOST_REASON_6]: '総合的判断',
            [this.LOST_REASON_9]: '不明',
        }
    }
}

export default LostReason;
