/**
 * 活動記録トラン
 */
import { isBlank } from '@/utilities/typing';
import Project from '@/models/entities/project';
import AppointmentSource from '@/models/entities/appointment-source';
import Area from '@/models/entities/area';
import DetailedRank from '@/models/entities/detailed-rank';
import Probability from '@/models/entities/probability';
import Certainty from '@/models/entities/certainty';
import User from '@/models/entities/user';
import ActivitySalesDocument from '@/models/entities/activity-sales-document';
import ActivityReportPresentDocument from '@/models/entities/activity-present-document';
import ActivityType from '@/models/enums/activity-type';
import ActivityDetailedType from '@/models/enums/activity-detailed-type';
import ActivityStandard from '@/models/entities/activity-standard';
import ActivityCompetitor from '@/models/entities/activity-competitor';
import CompanyScale from '@/models/enums/company-scale';
import Rank from '@/models/enums/rank';
import CountType from '@/models/enums/count-type';
import Purpose from '@/models/enums/purpose';
import Status from '@/models/enums/status';
import MeetingBy from '@/models/enums/meeting-by';
import Standard from '@/models/enums/standard';
import VoiceScene from '@/models/enums/voice-scene';
import HandleChatwork from '@/models/enums/handle-chatwork';

class Activity {
    activity_id;
    project;
    user;
    activity_type;
    activity_detailed_type;
    activity_date;
    activity_time;
    created_datetime;
    status;
    status_before;
    approach_detail;
    appointment_source;
    sales_user;
    appointment_datetime;
    meeting_minutes;
    appointment_note;
    company_name;
    contact_name;
    contact_name_kana;
    mobile_phone;
    email;
    zip_code;
    area;
    city;
    street;
    closest_station;
    meeting_by;
    attendance;
    web_meeting_url;
    web_meeting_account;
    web_meeting_place;
    visitor_meeting_room;
    website;
    representative_name;
    check_representative_name;
    check_representative_name_note;
    check_keyword_company_name;
    check_keyword_company_name_note;
    check_keyword_representative_name;
    check_keyword_representative_name_note;
    check_keyword_address;
    check_keyword_address_note;
    is_tradable;
    is_notified_chatwork;
    chatwork_message_id;
    company_scale;
    employee_number;
    rank;
    detailed_rank;
    expected_order_date;
    probability;
    certainty;
    count_type;
    competitor_note;
    purpose;
    fiscal_month;
    can_video_meeting;
    follow_plan;
    sales_note;
    consultant_note;
    meeting_detail;
    proposal_price;
    voice_scene;
    voice_content;
    voice_background;
    voice_action;
    department_id;

    sales_documents;
    present_documents;
    competitors;
    standards;

    // 更新時専用
    handle_chatwork;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.activity_id = properties.activity_id;
        this.project = new Project(properties.project);
        this.user = new User(properties.user);
        this.activity_type = properties.activity_type;
        this.activity_detailed_type = properties.activity_detailed_type;
        this.activity_date = properties.activity_date;
        this.activity_time = properties.activity_time;
        this.created_datetime = properties.created_datetime;
        this.status = properties.status;
        this.status_before = properties.status;
        this.approach_detail = properties.approach_detail;
        this.appointment_source = new AppointmentSource(properties.appointment_source);
        this.sales_user = new User(properties.sales_user);
        this.appointment_datetime = properties.appointment_datetime;
        this.meeting_minutes = properties.meeting_minutes;
        this.appointment_note = properties.appointment_note;
        this.company_name = properties.company_name;
        this.contact_name = properties.contact_name;
        this.contact_name_kana = properties.contact_name_kana;
        this.mobile_phone = properties.mobile_phone;
        this.email = properties.email;
        this.zip_code = properties.zip_code;
        this.area = new Area(properties.area);
        this.city = properties.city;
        this.street = properties.street;
        this.closest_station = properties.closest_station;
        this.meeting_by = properties.meeting_by;
        this.attendance = properties.attendance;
        this.web_meeting_url = properties.web_meeting_url;
        this.web_meeting_account = properties.web_meeting_account;
        this.web_meeting_place = properties.web_meeting_place;
        this.visitor_meeting_room = properties.visitor_meeting_room;
        this.website = properties.website;
        this.representative_name = properties.representative_name;
        this.check_representative_name = properties.check_representative_name;
        this.check_representative_name_note = properties.check_keyword_representative_name_note;
        this.check_keyword_company_name = properties.check_keyword_company_name;
        this.check_keyword_company_name_note = properties.check_keyword_company_name_note;
        this.check_keyword_representative_name = properties.check_keyword_representative_name;
        this.check_keyword_representative_name_note = properties.check_keyword_representative_name_note;
        this.check_keyword_address = properties.check_keyword_address;
        this.check_keyword_address_note = properties.check_keyword_address_note;
        this.is_tradable = properties.is_tradable;
        this.is_notified_chatwork = properties.is_notified_chatwork;
        this.chatwork_message_id = properties.chatwork_message_id;
        this.is_first_making_appointment = properties.is_first_making_appointment;
        this.company_scale = properties.company_scale;
        this.employee_number = properties.employee_number;
        this.rank = properties.rank;
        this.rank_before = properties.rank_before;
        this.detailed_rank = new DetailedRank(properties.detailed_rank);
        this.expected_order_date = properties.expected_order_date;
        this.probability = new Probability(properties.probability);
        this.certainty = new Certainty(properties.certainty);
        this.count_type = properties.count_type;
        this.competitor_note = properties.competitor_note;
        this.purpose = properties.purpose;
        this.fiscal_month = properties.fiscal_month;
        this.can_video_meeting = properties.can_video_meeting;
        this.follow_plan = properties.follow_plan;
        this.sales_note = properties.sales_note;
        this.consultant_note = properties.consultant_note;
        this.meeting_detail = properties.meeting_detail;
        this.proposal_price = properties.proposal_price;
        this.voice_scene = properties.voice_scene;
        this.voice_content = properties.voice_content;
        this.voice_background = properties.voice_background;
        this.voice_action = properties.voice_action;
        this.department_id = properties.department_id;

        // 営業資料
        this.sales_documents = [];
        if ('sales_documents' in properties) {
            for (let sales_document of properties.sales_documents) {
                this.sales_documents.push(new ActivitySalesDocument(sales_document));
            }
        }

        // プレゼント資料
        this.present_documents = [];
        if ('present_documents' in properties) {
            for (let present_document of properties.present_documents) {
                this.present_documents.push(new ActivityReportPresentDocument(present_document))
            }
        }

        // 規格
        this.standards = [];
        if ('standards' in properties) {
            for (let activity_standard of properties.standards) {
                this.standards.push(new ActivityStandard(activity_standard));
            }
        }

        // 競合
        this.competitors = [];
        if ('competitors' in properties) {
            for (let activity_competitor of properties.competitors) {
                this.competitors.push(new ActivityCompetitor(activity_competitor));
            }
        }

        this.handle_chatwork = HandleChatwork.NONE;
    }

    /**
     * getter
     */
    // 活動種別 表示
    get activity_type_label() {
        return ActivityType.get(this.activity_type);
    }

    // 件名 表示
    get activity_detailed_type_label() {
        return ActivityDetailedType.get(this.activity_detailed_type);
    }

    // ステータス 表示
    get status_label() {
        return Status.get(this.status);
    }

    // 会社規模 表示
    get company_scale_label() {
        return CompanyScale.get(this.company_scale);
    }

    // 評価 表示
    get rank_label() {
        return Rank.get(this.rank);
    }

    // 計測カウント 表示
    get count_type_label() {
        return CountType.get(this.count_type);
    }

    // 取得理由 表示
    get purpose_label() {
        return Purpose.get(this.purpose);
    }

    // 打合せ方法 表示
    get meeting_by_label() {
        return MeetingBy.get(this.meeting_by);
    }

    // お客様の声 場面 表示
    get voice_scene_label() {
        return VoiceScene.get(this.voice_scene);
    }

    // 競合
    get competitors_entities() {
        return this.competitors.map(activity_competitor => activity_competitor.competitor);
    }

    set competitors_entities(rows) {
        this.competitors = rows.map(row => {
            return new ActivityCompetitor({
                competitor: row,
            });
        });
    }

    // 競合 複数表示
    get competitors_label() {
        if (this.competitors.length === 0) {
            return null;
        }

        return this.competitors.map((activity_competitor) => {
            return activity_competitor.competitor.competitor_name;
        }).join(', ');
    }

    // 規格
    get standards_values() {
        return this.standards.map(activity_standard => activity_standard.standard);
    }

    set standards_values(rows) {
        this.standards = rows.map(row => {
            return new ActivityStandard({
                standard: row,
            });
        });
    }

    // 規格 複数表示
    get standards_label() {
        if (this.standards.length === 0) {
            return null;
        }

        return this.standards.map(standard => {
            return Standard.get(standard.standard)
        }).join(', ');
    }

    // 営業資料
    get sales_documents_values() {
        return this.sales_documents.map(activity_sales_document => activity_sales_document.document.document_id);
    }

    set sales_documents_values(rows) {
        this.sales_documents = rows.map(row => {
            const activity_sales_document = new ActivitySalesDocument();
            activity_sales_document.document.document_id = row;
            return activity_sales_document;
        });
    }

    get sales_documents_label() {
        if (this.sales_documents.length === 0) {
            return null;
        }

        return this.sales_documents.map((sales_document) => {
            return sales_document.document?.document_name;
        }).join(', ');
    }

    // プレゼント資料
    get present_documents_values() {
        return this.present_documents.map(activity_present_document => activity_present_document.document.document_id);
    }

    set present_documents_values(rows) {
        this.present_documents = rows.map(row => {
            const activity_present_document = new ActivityReportPresentDocument();
            activity_present_document.document.document_id = row;
            return activity_present_document;
        });
    }

    get present_documents_label() {
        if (this.present_documents.length === 0) {
            return null;
        }

        return this.present_documents.map((present_document) => {
            return present_document.document?.document_name;
        }).join(', ');
    }
}

export default Activity;
