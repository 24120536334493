import Enum from './enum'

/**
 * 失注フラグ
 */
class IsLostCompetitor extends Enum {
    static YES = 1;
    static NO = 0;
}

export default IsLostCompetitor;
