import Enum from './enum'

/**
 * 受注理由
 */
class OrderedReason extends Enum {
    static ORDERED_REASON_1 = 1; // 価格
    static ORDERED_REASON_2 = 2; // サービス内容
    static ORDERED_REASON_3 = 3; // 営業対応
    static ORDERED_REASON_5 = 5; // 繋がり
    static ORDERED_REASON_6 = 6; // 総合的判断
    static ORDERED_REASON_9 = 9; // 不明

    static values() {
        return {
            [this.ORDERED_REASON_1]: '価格',
            [this.ORDERED_REASON_2]: 'サービス内容',
            [this.ORDERED_REASON_3]: '営業対応',
            [this.ORDERED_REASON_5]: '繋がり',
            [this.ORDERED_REASON_6]: '総合的判断',
            [this.ORDERED_REASON_9]: '不明',
        }
    }
}

export default OrderedReason;
