/**
 * 案件連絡先トラン
 */
import { isBlank } from '@/utilities/typing';
import Project from '@/models/entities/project';

class ProjectFile {
    project_file_id;
    project;
    file_label;
    file_name;
    file_path;
    thumbnail_path;
    mime_type;
    file_size;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.project_file_id = properties.project_file_id;
        this.project = new Project(properties.project);
        this.file_label = properties.file_label;
        this.file_name = properties.file_name;
        this.file_path = properties.file_path;
        this.thumbnail_path = properties.thumbnail_path;
        this.mime_type = properties.mime_type;
        this.file_size = properties.file_size;
    }
}

export default ProjectFile;
