import Enum from './enum'

/**
 * 打合せ方法
 */
class MeetingBy extends Enum {
    static VISITING = 1; //訪問
    static WEB_MEETING = 2; //Web会議
    static COMING = 3; //来社
    static TEL = 4; //電話

    static values() {
        return {
            [this.VISITING]: '訪問',
            [this.WEB_MEETING]: 'Web会議',
            [this.COMING]: '来社',
            [this.TEL]: '電話',
        }
    }
}

export default MeetingBy;
