import Enum from './enum'

/**
 * リスケフラグ
 */
class IsRescheduling extends Enum {
    static YES = 1; // リスケ
    static NO = 0; // オンスケ

    static values() {
        return {
            [this.YES]: 'リスケ',
            [this.NO]: 'オンスケ',
        }
    }
}

export default IsRescheduling;
