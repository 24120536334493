import Enum from './enum'

/**
 * 更新時の Chatwork メッセージの扱い
 */
class HandleChatwork extends Enum {
    static NONE = 0;
    static UPDATE = 1;
    static RESEND = 2;

    static values() {
        return {
            [this.NONE]: '何もしない',
            [this.UPDATE]: '送信済のメッセージを編集する',
            [this.RESEND]: 'メッセージを再送する',
        }
    }
}

export default HandleChatwork;
