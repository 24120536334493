/**
 * 活動記録プレゼン資料トラン
 */
import { isBlank } from '@/utilities/typing';
import Document from '@/models/entities/document';

class ActivityPresentDocument {
    activity_present_document_id;
    activity_id;
    document;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.activity_present_document_id = properties.activity_present_document_id;
        this.activity_id = properties.activity_id;
        this.document = new Document(properties.document);
    }
}

export default ActivityPresentDocument;
