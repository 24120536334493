/**
 * フィードバックトラン
 */
import { isBlank } from '@/utilities/typing';
import Project from '@/models/entities/project';
import User from '@/models/entities/user';

class Feedback {
    feedback_id;
    project;
    user;
    feedback_datetime;
    feedback_detail;
    is_unread;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.feedback_id = properties.feedback_id;
        this.project = new Project(properties.project);
        this.user = new User(properties.user);
        this.feedback_datetime = properties.feedback_datetime;
        this.feedback_detail = properties.feedback_detail;
        this.is_unread = properties.is_unread;
    }
}

export default Feedback;
