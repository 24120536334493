import Enum from './enum'

/**
 * 大手企業フラグ
 */
class IsMajor extends Enum {
    static YES = 1;
    static NO = 0;
}

export default IsMajor;
