import Enum from './enum'

/**
 * 件名
 */
class ActivityDetailedType extends Enum {
    static FOLLOW = 1;
    static PHONE = 2;
    static ABSENCE = 3;
    static THANKYOU_MAIL = 4;
    static SEND_MAIL = 5;
    static RECEIVE_MAIL = 6;
    static APPOINTMENT = 7;
    static NEW_VISIT = 8;
    static REVISIT = 9;
    static OTHER = 10;

    static values() {
        return {
            [this.FOLLOW]: '追跡',
            [this.PHONE]: '電話',
            [this.ABSENCE]: '不在',
            [this.THANKYOU_MAIL]: 'お礼メール送付',
            [this.SEND_MAIL]: 'メール送付',
            [this.RECEIVE_MAIL]: 'メール受信',
            [this.APPOINTMENT]: 'アポ取得',
            [this.NEW_VISIT]: '新規訪問',
            [this.REVISIT]: '再訪問',
            [this.OTHER]: 'その他',
        }
    }

    static approachValues() {
        return {
            [this.FOLLOW]: '追跡',
            [this.PHONE]: '電話',
            [this.ABSENCE]: '不在',
            [this.THANKYOU_MAIL]: 'お礼メール送付',
            [this.SEND_MAIL]: 'メール送付',
            [this.RECEIVE_MAIL]: 'メール受信',
            [this.OTHER]: 'その他',
        }
    }

    static approachOptions() {
        let object = this.approachValues();
        let array = [];
        let keys = Object.keys(object);
        keys.forEach((key) => {
            array.push({
                value: key,
                label: object[key],
            });
        });

        return array;
    }

    static appointmentValues() {
        return {
            [this.APPOINTMENT]: 'アポ取得',
        }
    }

    static appointmentOptions() {
        let object = this.appointmentValues();
        let array = [];
        let keys = Object.keys(object);
        keys.forEach((key) => {
            array.push({
                value: key,
                label: object[key],
            });
        });

        return array;
    }

    static meetingValues() {
        return {
            [this.NEW_VISIT]: '新規訪問',
            [this.REVISIT]: '再訪問',
        }
    }

    static meetingOptions() {
        let object = this.meetingValues();
        let array = [];
        let keys = Object.keys(object);
        keys.forEach((key) => {
            array.push({
                value: key,
                label: object[key],
            });
        });

        return array;
    }
}

export default ActivityDetailedType;
