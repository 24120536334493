/**
 * 案件トラン
 */
import { isBlank } from '@/utilities/typing'
import Customer from '@/models/entities/customer';
import Department from '@/models/entities/department';
import User from '@/models/entities/user';
import Channel from '@/models/entities/channel';
import Probability from '@/models/entities/probability';
import Certainty from '@/models/entities/certainty';
import Competitor from '@/models/entities/competitor';
import DetailedRank from '@/models/entities/detailed-rank';
import Area from '@/models/entities/area';
import DetailedChannel from '@/models/entities/detailed-channel';
import AppointmentSource from '@/models/entities/appointment-source';
import ProjectStandard from '@/models/entities/project-standard';
import ProjectCompetitor from '@/models/entities/project-competitor';
import ProjectContact from '@/models/entities/project-contact';
import ProjectFile from '@/models/entities/project-file';
import ProjectHistory from '@/models/entities/project-history';
import Feedback from '@/models/entities/feedback';
import Duplicate from '@/models/entities/duplicate';
import Activity from '@/models/entities/activity';
import Status from '@/models/enums/status';
import Rank from '@/models/enums/rank';
import CountType from '@/models/enums/count-type';
import ContractType from '@/models/enums/contract-type';
import ContractStatus from '@/models/enums/contract-status';
import Purpose from '@/models/enums/purpose';
import SubscribeMailMagazine from '@/models/enums/subscribe-mail-magazine';
import LostReason from '@/models/enums/lost-reason';
import OrderedReason from '@/models/enums/ordered-reason';
import Standard from '@/models/enums/standard';
import ContactType from '@/models/enums/contact-type';
import IsRescheduling from '@/models/enums/is-rescheduling';
import CanVideoMeeting from '@/models/enums/can-video-meeting';
import IsLostCompetitor from '@/models/enums/is-lost-competitor';

class Project {
    project_id; // 案件ID
    customer; // 顧客
    department; // 事業部
    status; // ステータス
    contact_user; // 反響対応者
    follow_user; // 追客担当
    count_type; // 計測カウント
    contact_date; // 反響日
    contact_department; // 反響時事業部
    referrer; // 紹介元
    channel; // チャネル
    contact_note; // 反響備考
    referrer_company; // 紹介元（お客様名）
    referrer_company_consultant_user; // 担当コンサル
    sales_user; // 営業担当
    first_appointment_datetime; // 初回アポイント日時
    latest_appointment_datetime; // 最新アポイント日時
    appointment_note; // アポイント備考
    probability; // 優先度
    certainty; // 確実度
    competitor_note; // 競合(その他)
    purpose; // 取得理由
    standard_note; // その他規格・備考
    standard_transferred; // 規格自動変更日
    proposal_note; // ご提案プラスワン
    sales_note; // 注意事項
    consultant_note; // 担当コンサル選定時の注意事項
    can_video_meeting; // ビデオ通話
    next_approaching_date; // 次回アプローチ日
    campaign_number; // キャンペーンNo
    follow_plan; // 追客計画
    rank; // 評価
    detailed_rank; // サブ評価
    expected_order_date; // 見込日
    is_rescheduling; // リスケ
    zip_code; // 郵便番号
    area_id; // 都道府県
    city; // 市区郡
    street; // 町名・番地
    closest_station; // 最寄駅
    google_map; // Google Maps
    subscribe_mail_magazine; // メールマガジン購読
    detailed_channel; // 送信元ページ
    form_document; // 資料請求
    form_consideration; // ご検討事項
    form_request; // ご希望のサービス内容
    form_inquiry; // お問合せ内容／ご相談内容／ご相談・ご要望・希望日
    lost_date; // 失注日
    lost_cause; // 失注理由
    lost_reason; // 他社落ち理由
    history; // 過去の訪問・追跡履歴
    created_user; // 作成者
    created_datetime;
    updated_user; // 最終更新者
    updated_datetime; //最終更新日
    ordered_date; // 受注日
    ordered_reason; // 受注理由
    ordered_reason_detail; // 受注理由詳細
    client_number; // 顧客番号
    contract_price;//契約金額
    contract_status; // 契約ステータス
    contract_request_review_date; // 契約書原案送付日
    contract_request_sign_date; // クラウドサイン送付日
    contract_execute_date; // 契約締結完了日
    first_visited_date; // 初回営業日
    latest_update_status_date; // ステータス変更日
    appointment_source; // アポ取得リスト
    sync_spreadsheet_status; // データ連携ステータス
    first_making_appointment_date; // 初回アポ取得日
    latest_making_appointment_date; // 最新アポ取得日
    first_making_appointment_user; // 初回アポ取得者
    latest_making_appointment_user; // 最新アポ取得者
    certification_expiry_date; // 認証有効期限日
    contract_type; // 契約形態
    proposal_price; // 提案金額
    lost_against_competitor;

    competitors;
    lost_competitors;
    standards;
    contacts; //客先担当者（全員）
    files;
    activities;
    activities_oldest; //古い順
    feedbacks;
    duplicates;

    latest_feedback;
    latest_feedback_original;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.project_id = properties.project_id; // 案件ID
        this.customer = new Customer(properties.customer); // 顧客
        this.department = new Department(properties.department); // 事業部
        this.status = properties.status; // ステータス
        this.no_follow = properties.no_follow ?? 0; // 追客なし
        this.contact_user = new User(properties.contact_user); // 反響対応者
        this.follow_user = new User(properties.follow_user); // 追客担当
        this.count_type = properties.count_type; // 計測カウント
        this.contact_date = properties.contact_date; // 反響日
        this.contact_department = new Department(properties.contact_department); // 反響時事業部
        this.referrer = properties.referrer; // 紹介元
        this.channel = new Channel(properties.channel); // チャネル
        this.contact_note = properties.contact_note; // 反響備考
        this.referrer_company = properties.referrer_company; // 紹介元（お客様名）
        this.referrer_company_consultant_user = new User(properties.referrer_company_consultant_user); // 担当コンサル
        this.sales_user = new User(properties.sales_user); // 営業担当
        this.first_appointment_datetime = properties.first_appointment_datetime; // 初回アポイント日時
        this.latest_appointment_datetime = properties.latest_appointment_datetime; // 最新アポイント日時
        this.appointment_note = properties.appointment_note; // アポイント備考
        this.probability = new Probability(properties.probability); // 優先度
        this.certainty = new Certainty(properties.certainty); // 確実度
        this.competitor_note = properties.competitor_note; // 競合(その他)
        this.purpose = properties.purpose; // 取得理由
        this.standard_note = properties.standard_note; // その他規格・備考
        this.standard_transferred = properties.standard_transferred; // 規格自動変更日
        this.proposal_note = properties.proposal_note; // ご提案プラスワン
        this.sales_note = properties.sales_note; // 注意事項
        this.consultant_note = properties.consultant_note; // 担当コンサル選定時の注意事項
        this.can_video_meeting = properties.can_video_meeting ?? 0; // ビデオ通話
        this.next_approaching_date = properties.next_approaching_date; // 次回アプローチ日
        this.campaign_number = properties.campaign_number; // キャンペーンNo
        this.follow_plan = properties.follow_plan; // 追客計画
        this.rank = properties.rank; // 評価
        this.detailed_rank = new DetailedRank(properties.detailed_rank); // サブ評価
        this.expected_order_date = properties.expected_order_date; // 見込日
        this.is_rescheduling = properties.is_rescheduling ?? 0; // リスケ
        this.zip_code = properties.zip_code; // 郵便番号
        this.area = new Area(properties.area); // 都道府県
        this.city = properties.city; // 市区郡
        this.street = properties.street; // 町名・番地
        this.closest_station = properties.closest_station; // 最寄駅
        this.google_map = properties.google_map; // Google Maps
        this.subscribe_mail_magazine = properties.subscribe_mail_magazine; // メールマガジン購読
        this.detailed_channel = new DetailedChannel(properties.detailed_channel); // 送信元ページ
        this.form_document = properties.form_document; // 資料請求
        this.form_consideration = properties.form_consideration; // ご検討事項
        this.form_request = properties.form_request; // ご希望のサービス内容
        this.form_inquiry = properties.form_inquiry; // お問合せ内容／ご相談内容／ご相談・ご要望・希望日
        this.lost_date = properties.lost_date; // 失注日
        this.lost_cause = properties.lost_cause; // 失注理由
        this.lost_reason = properties.lost_reason; // 他社落ち理由
        this.history = properties.history; // 過去の訪問・追跡履歴
        this.created_user = new User(properties.created_user); // 作成者
        this.created_datetime = properties.created_datetime; // 作成日時
        this.updated_user = new User(properties.updated_user); // 最終更新者
        this.updated_datetime = properties.updated_datetime; // 最終更新日時
        this.ordered_date = properties.ordered_date; // 受注日
        this.ordered_reason = properties.ordered_reason; // 受注理由
        this.ordered_reason_detail = properties.ordered_reason_detail; // 受注理由詳細
        this.client_number = properties.client_number; // 顧客番号
        this.contract_price = properties.contract_price; //契約金額
        this.contract_status = properties.contract_status; // 契約ステータス
        this.contract_request_review_date = properties.contract_request_review_date; // 契約書原案送付日
        this.contract_request_sign_date = properties.contract_request_sign_date; // クラウドサイン送付日
        this.contract_execute_date = properties.contract_execute_date; // 契約締結完了日
        this.first_visited_date = properties.first_visited_date; // 初回営業日
        this.latest_update_status_date = properties.latest_update_status_date; // ステータス変更日
        this.appointment_source = new AppointmentSource(properties.appointment_source); // アポ取得リスト
        this.sync_spreadsheet_status = properties.sync_spreadsheet_status; // データ連携ステータス
        this.first_making_appointment_date = properties.first_making_appointment_date; // 初回アポ取得日
        this.latest_making_appointment_date = properties.latest_making_appointment_date; // 最新アポ取得日
        this.first_making_appointment_user = new User(properties.first_making_appointment_user); // 初回アポ取得者
        this.latest_making_appointment_user = new User(properties.latest_making_appointment_user); // 最新アポ取得者
        this.certification_expiry_date = properties.certification_expiry_date; // 認証有効期限日
        this.contract_type = properties.contract_type;
        this.proposal_price = properties.proposal_price;
        this.lost_against_competitor = new Competitor(properties.lost_against_competitor); // 失注先

        // 案件競合トラン
        this.competitors = [];
        if ('competitors' in properties) {
            for (let competitor of properties.competitors) {
                this.competitors.push(new ProjectCompetitor(competitor));
            }
        }

        // 案件競合トラン 候補落ち
        this.lost_competitors = [];
        if ('lost_competitors' in properties) {
            for (let lost_competitor of properties.lost_competitors) {
                this.lost_competitors.push(new ProjectCompetitor(lost_competitor));
            }
        }

        // 案件規格
        this.standards = [];
        if ('standards' in properties) {
            for (let standard of properties.standards) {
                this.standards.push(new ProjectStandard(standard));
            }
        }

        // 案件連絡先（客先担当者（全員））
        this.contacts = [];
        if ('contacts' in properties) {
            for (let contact of properties.contacts) {
                this.contacts.push(new ProjectContact(contact));
            }
        }

        // 案件ファイル
        this.files = [];
        if ('files' in properties) {
            for (let file of properties.files) {
                this.files.push(new ProjectFile(file));
            }
        }

        // 活動記録
        this.activities = [];
        if ('activities' in properties) {
            for (let activity of properties.activities) {
                this.activities.push(new Activity(activity));
            }
        }

        // 活動記録 古い順
        this.activities_oldest = [];
        if ('activities' in properties) {
            if (properties.activities.length) {
                // ディープコピー
                let activities_oldest = properties.activities.map( activity => ({...activity}));
                // ソート順逆転（古い順）
                activities_oldest.reverse();

                for (let activity of activities_oldest) {
                    this.activities_oldest.push(new Activity(activity));
                }
            }

        }

        // フィードバック
        this.feedbacks = [];
        if ('feedbacks' in properties) {
            for (let feedback of properties.feedbacks) {
                this.feedbacks.push(new Feedback(feedback));
            }
            if (properties.feedbacks.length > 0) {
                this.latest_feedback = new Feedback(properties.feedbacks[0]);
                this.latest_feedback_original = new Feedback(properties.feedbacks[0]);
            } else {
                this.latest_feedback = new Feedback();
                this.latest_feedback_original = new Feedback();
            }
        }

        // 重複アラート
        this.duplicates = [];
        if ('duplicates' in properties) {
            for (let duplicate of properties.duplicates) {
            this.duplicates.push(new Duplicate(duplicate));
            }
        }

        // 案件履歴
        this.histories = [];
        if ('histories' in properties) {
            for (let history of properties.histories) {
            this.histories.push(new ProjectHistory(history));
            }
        }
    }

    /**
     * getter
     */
    // ステータス 表示
    get status_label() {
        return Status.get(this.status);
    }

    // 評価 表示
    get rank_label() {
        return Rank.get(this.rank);
    }

    // 計測カウント 表示
    get count_type_label() {
        return CountType.get(this.count_type);
    }

    // 取得理由 表示
    get purpose_label() {
        return Purpose.get(this.purpose);
    }

    // メールマガジン購読 表示
    get subscribe_mail_magazine_label() {
        return SubscribeMailMagazine.get(this.subscribe_mail_magazine);
    }

    // 失注日 表示
    get lost_date_label() {
        if (!this.lost_date) {
            return null;
        }

        return this.lost_date.replace(/(.+)-(.+)-(.+)/g,'$1/$2/$3');
    }

    // 他社落ち理由 表示
    get lost_reason_label() {
        return LostReason.get(this.lost_reason);
    }

    // 受注理由 表示
    get ordered_reason_label() {
        return OrderedReason.get(this.ordered_reason);
    }

    // 契約ステータス 表示
    get contract_status_label() {
        return ContractStatus.get(this.contract_status);
    }

    // 見込日 表示
    get expected_order_date_label() {
        if (!this.expected_order_date) {
            return null;
        }

        return this.expected_order_date.replace(/(.+)-(.+)-(.+)/g,'$1/$2/$3');
    }

    // 受注日 表示
    get ordered_date_label() {
        if (!this.ordered_date) {
            return null;
        }

        return this.ordered_date.replace(/(.+)-(.+)-(.+)/g,'$1/$2/$3');
    }

    // 反響日 表示
    get contact_date_label() {
        if (!this.contact_date) {
            return null;
        }

        return this.contact_date.replace(/(.+)-(.+)-(.+)/g,'$1/$2/$3');
    }

    // 初回営業日 表示
    get first_visited_date_label() {
        if (!this.first_visited_date) {
            return null;
        }

        return this.first_visited_date.replace(/(.+)-(.+)-(.+)/g,'$1/$2/$3');
    }

    // 次回アプローチ日 表示
    get next_approaching_date_label() {
        if (!this.next_approaching_date) {
            return null;
        }

        return this.next_approaching_date.replace(/(.+)-(.+)-(.+)/g,'$1/$2/$3');
    }

    // 初回アポイント日時 表示
    get first_appointment_datetime_label() {
        if (!this.first_appointment_datetime) {
            return null;
        }

        return this.first_appointment_datetime.replace(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/, '$1-$2-$3 $4:$5');
    }

    // 最新アポイント日時 表示
    get latest_appointment_datetime_label() {
        if (!this.latest_appointment_datetime) {
            return null;
        }

        return this.latest_appointment_datetime.replace(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/, '$1-$2-$3 $4:$5');
    }

    // 初回アポ取得日 表示
    get first_making_appointment_date_label() {
        if (!this.first_making_appointment_date) {
            return null;
        }

        return this.first_making_appointment_date.replace(/(.+)-(.+)-(.+)/g,'$1/$2/$3');
    }

    // 最新アポ取得日 表示
    get latest_making_appointment_date_label() {
        if (!this.latest_making_appointment_date) {
            return null;
        }

        return this.latest_making_appointment_date.replace(/(.+)-(.+)-(.+)/g,'$1/$2/$3');
    }

    // 郵便番号表示
    get zip_code_label() {
        if (!this.zip_code) {
            return null;
        }

        return '〒 ' + this.zip_code;
    }

    // 認証有効期限日 表示
    get certification_expiry_date_label() {
        if (!this.certification_expiry_date) {
            return null;
        }

        return this.certification_expiry_date.replace(/(.+)-(.+)-(.+)/g,'$1/$2/$3');
    }

    // 客先担当者（主担当） 取得
    get primary_contact() {
        const primary_contact = this.contacts.find((contact) => {
            return contact.contact_type === ContactType.PRIMARY;
        });

        if (!primary_contact) {
            return null;
        }

        return primary_contact;
    }

    // リスケ 表示
    get is_rescheduling_label() {
        return this.is_rescheduling === IsRescheduling.YES ? '○' : '×';
    }

    // ビデオ通話 表示
    get can_video_meeting_label() {
        return this.can_video_meeting === CanVideoMeeting.YES ? '○' : '×';
    }

    // 契約形態 表示
    get contract_type_label() {
        return ContractType.get(this.contract_type);
    }

    // 競合 複数表示
    get competitors_label() {
        if (this.competitors.length === 0) {
            return null;
        }

        return this.competitors.map((project_competitor) => {
            if (project_competitor.is_lost_competitor === IsLostCompetitor.NO) {
                return project_competitor.competitor.competitor_name;
            }
        }).join(', ');
    }

    // 他社落ち競合 複数表示
    get lost_competitors_label() {
        if (this.lost_competitors.length === 0) {
            return null;
        }

        return this.lost_competitors.map((project_competitor) => {
            if (project_competitor.is_lost_competitor === IsLostCompetitor.YES) {
                return project_competitor.competitor.competitor_name;
            }
        }).join(', ');
    }

    // 規格 複数表示
    get standards_label() {
        if (this.standards.length === 0) {
            return null;
        }

        return this.standards.map(standard => {
            return Standard.get(standard.standard)
        }).join(', ');
    }

    // 規格 複数表示＋その他
    get standards_label_all() {
        let labels = this.standards.map(standard => {
            return Standard.get(standard.standard)
        });

        if (!isBlank(this.standard_note)) {
            labels.push(this.standard_note);
        }

        return labels.join(', ');
    }

    // 競合
    get competitors_entities() {
        return this.competitors.map(project_competitor => project_competitor.competitor);
    }

    set competitors_entities(rows) {
        this.competitors = rows.map((row) => {
            return new ProjectCompetitor({
                competitor: row,
                is_lost_competitor: IsLostCompetitor.NO
            });
        });
    }

    // 候補落ち競合
    get lost_competitors_entities() {
        return this.lost_competitors.map(project_competitor => project_competitor.competitor);
    }

    set lost_competitors_entities(rows) {
        this.lost_competitors = rows.map(row => {
            return new ProjectCompetitor({
                competitor: row,
                is_lost_competitor: IsLostCompetitor.YES
            });
        });
    }

    // 規格
    get standards_values() {
        return this.standards.map(project_standard => project_standard.standard);
    }

    set standards_values(rows) {
        this.standards = rows.map(row => {
            return new ProjectStandard({
                standard: row,
            });
        });
    }
}

export default Project;
