/**
 * 顧客マスタ
 */
import { isBlank } from '@/utilities/typing';
import { numberFormat } from '@/utilities/text-format';
import Industry from '@/models/enums/industry';
import CompanyScale from '@/models/enums/company-scale';
import IsMajor from '@/models/enums/is-major';
import IsDeliveryStop from '@/models/enums/is-delivery-stop';
import IsTradable from '@/models/enums/is-tradable';
import CanElectronicContract from '@/models/enums/can-electronic-contract';
import Project from '@/models/entities/project';

class Customer {
    customer_id;
    company_name;
    company_scale;
    is_major;
    employee_number;
    is_tradable;
    is_delivery_stop;
    representative_name;
    website;
    phone;
    fax;
    industry;
    address;
    fiscal_month;
    can_electronic_contract;
    check_representative_name;
    check_representative_name_note;
    check_keyword_company_name;
    check_keyword_company_name_note;
    check_keyword_representative_name;
    check_keyword_representative_name_note;
    check_keyword_address;
    check_keyword_address_note;
    note;

    projects;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.customer_id = properties.customer_id;
        this.company_name = properties.company_name;
        this.company_scale = properties.company_scale;
        this.is_major = properties.is_major;
        this.employee_number = properties.employee_number;
        this.is_tradable = properties.is_tradable;
        this.is_delivery_stop = properties.is_delivery_stop;
        this.representative_name = properties.representative_name;
        this.website = properties.website;
        this.phone = properties.phone;
        this.fax = properties.fax;
        this.industry = properties.industry;
        this.address = properties.address;
        this.fiscal_month = properties.fiscal_month;
        this.can_electronic_contract = properties.can_electronic_contract;
        this.check_representative_name = properties.check_representative_name;
        this.check_representative_name_note = properties.check_representative_name_note;
        this.check_keyword_company_name = properties.check_keyword_company_name;
        this.check_keyword_company_name_note = properties.check_keyword_company_name_note;
        this.check_keyword_representative_name = properties.check_keyword_representative_name;
        this.check_keyword_representative_name_note = properties.check_keyword_representative_name_note;
        this.check_keyword_address = properties.check_keyword_address;
        this.check_keyword_address_note = properties.check_keyword_address_note;
        this.note = properties.note;

        this.projects = [];
        if ('projects' in properties) {
            for (let project of properties.projects) {
                this.projects.push(new Project(project))
            }
        }
    }

    /**
     * getter
     */
    // 業種 表示
    get industry_label() {
        return Industry.get(this.industry);
    }

    // 会社規模 表示
    get company_scale_label() {
        return CompanyScale.get(this.company_scale);
    }

    // 取引可否
    get is_tradable_label() {
        return IsTradable.get(this.is_tradable);
    }

    // 配信停止
    get is_delivery_stop_label() {
        return IsDeliveryStop.get(this.is_delivery_stop);
    }

    // 決算期
    get fiscal_month_label() {
        if (!this.fiscal_month) {
            return null;
        }

        return this.fiscal_month + '月';
    }

    // 大手企業 表示
    get is_major_label() {
        return this.is_major === IsMajor.YES ? '○' : '×';
    }

    // 電子契約
    get can_electronic_contract_label() {
        return CanElectronicContract.get(this.can_electronic_contract);
    }

    // 従業員数
    get employee_number_label() {
        if (!this.employee_number) {
            return null;
        }

        return numberFormat(this.employee_number) + ' 名';
    }

}

export default Customer;
