import Enum from './enum'

/**
 * 取引可否
 */
class IsTradable extends Enum {
    static YES = 1; // 可
    static NO = 0; // 不可

    static values() {
        return {
            [this.YES]: '取引可',
            [this.NO]: '取引不可',
        }
    }
}

export default IsTradable;
