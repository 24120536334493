/**
 * 活動記録競合トラン
 */
import { isBlank } from '@/utilities/typing';
import Activity from '@/models/entities/activity';
import Competitor from '@/models/entities/competitor';

class ActivityCompetitor {
    activity_competitor_id;
    activity;
    competitor;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.activity_competitor_id = properties.activity_competitor_id;
        this.activity = new Activity(properties.activity);
        this.competitor = new Competitor(properties.competitor);
    }
}

export default ActivityCompetitor;
