import Enum from './enum'

/**
 * 配信停止フラグ
 */
class IsDeliveryStop extends Enum {
    static YES = 1;
    static NO = 0;

    static values() {
        return {
            [this.YES]: '配信停止',
            [this.NO]: '配信可',
        }
    }
}

export default IsDeliveryStop;
