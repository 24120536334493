import Enum from './enum'

/**
 * 電子契約可・不可
 */
class CanElectronicContract extends Enum {
    static YES = 1; // 可
    static NO = 0; // 不可

    static values() {
        return {
            [this.YES]: '可',
            [this.NO]: '不可',
        }
    }
}

export default CanElectronicContract;
