import Enum from './enum'

/**
 * ビデオ通話フラグ
 */
class CanVideoMeeting extends Enum {
    static YES = 1;
    static NO = 0;
}

export default CanVideoMeeting;
