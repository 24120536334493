import Enum from './enum'

/**
 * 活動種別
 */
class ActivityType extends Enum {
    static APPROACH = 1;
    static APPOINTMENT = 2;
    static MEETING = 3;

    static values() {
        return {
            [this.APPROACH]: 'アプローチ記録',
            [this.APPOINTMENT]: 'アポ取得報告',
            [this.MEETING]: '営業報告',
        }
    }
}

export default ActivityType;
