import Enum from './enum'

/**
 * 重複アラートトラン
 */
class DuplicateStatus extends Enum {
    static UNTREATED = 1;
    static INTEGRATED = 2;
    static CONFIRMED_NOT_DUPLICATE = 3;

    static values() {
        return {
            [this.UNTREATED]: '未処理',
            [this.INTEGRATED]: '統合済',
            [this.CONFIRMED_NOT_DUPLICATE]: '重複ではないことを確認済',
        }
    }
}

export default DuplicateStatus;
