/**
 * 案件連絡先トラン
 */
import { isBlank } from '@/utilities/typing';
import Project from '@/models/entities/project';
import ContactType from '@/models/enums/contact-type';

class ProjectContact {
    project_contact_id;
    project;
    contact_name;
    contact_name_kana;
    is_ceo;
    position;
    division;
    phone;
    email;
    contact_type;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.project_contact_id = properties.project_contact_id;
        this.project = new Project(properties.project);
        this.contact_name = properties.contact_name;
        this.contact_name_kana = properties.contact_name_kana;
        this.is_ceo = properties.is_ceo;
        this.position = properties.position;
        this.division = properties.division;
        this.phone = properties.phone;
        this.email = properties.email;
        this.contact_type = properties.contact_type;
    }

    /**
     * getter
     */
    // 活動種別 表示
    get contact_type_label() {
        return ContactType.get(this.contact_type);
    }
}

export default ProjectContact;
