import Enum from './enum'

/**
 * 業種
 */
class Industry extends Enum {
    static MANUFACTURE = 1;
    static CONSTRUCTION = 2;
    static TRANSPORTATION = 3;
    static ESTATE = 4;
    static TRADING_COMPANY = 5;
    static TEMPORARY_STAFFING = 6;
    static PRINTING = 7;
    static PROFESSIONAL = 8;
    static ADVERTISING = 9;
    static IT = 10;

    static values() {
        return {
            [this.MANUFACTURE]: '製造業',
            [this.CONSTRUCTION]: '建築・土木',
            [this.TRANSPORTATION]: '運輸物流',
            [this.ESTATE]: '不動産・施設管理・総合ビルメンテナンス',
            [this.TRADING_COMPANY]: '商社・メーカー',
            [this.TEMPORARY_STAFFING]: '人材派遣',
            [this.PRINTING]: '印刷・書籍関連',
            [this.PROFESSIONAL]: '士業',
            [this.ADVERTISING]: '広告関連',
            [this.IT]: 'IT',
        }
    }
}

export default Industry;
