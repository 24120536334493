/**
 * 活動記録競合トラン
 */
import { isBlank } from '@/utilities/typing';
import Activity from '@/models/entities/activity';

class ActivityStandard {
    activity_standard_id;
    activity;
    standard;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.activity_standard_id = properties.activity_standard_id;
        this.activity = new Activity(properties.activity);
        this.standard = properties.standard;
    }
}

export default ActivityStandard;
