import Enum from './enum'

/**
 * 契約形態
 */
class ContractType extends Enum {
    static ANNUAL = 1; //年間
    static SHOT = 2; //ショット

    static values() {
        return {
            [this.ANNUAL]: '年間',
            [this.SHOT]: 'ショット'
        }
    }
}

export default ContractType;
