import Enum from './enum'

/**
 * 会社規模
 */
class CompanyScale extends Enum {
    static THE_NUMBERS_1_10 = 1;
    static THE_NUMBERS_11_50 = 2;
    static THE_NUMBERS_51_100 = 3;
    static THE_NUMBERS_101_300 = 4;
    static THE_NUMBERS_OVER_300 = 5;

    static values() {
        return {
            [this.THE_NUMBERS_1_10]: '1 〜 10名',
            [this.THE_NUMBERS_11_50]: '11 〜 50名',
            [this.THE_NUMBERS_51_100]: '51 〜 100名',
            [this.THE_NUMBERS_101_300]: '101 〜 300名',
            [this.THE_NUMBERS_OVER_300]: '300名〜',
        }
    }
}

export default CompanyScale;
