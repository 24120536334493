/**
 * 案件履歴トラン
 */
import { isBlank } from '@/utilities/typing';
import Project from '@/models/entities/project';
import User from '@/models/entities/user';
import Status from '@/models/enums/status';

class ProjectFile {
    project_history_id;
    project;
    before_status;
    after_status;
    before_follow_user;
    after_follow_user;
    edit_datetime;
    user;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.project_history_id = properties.project_history_id;
        this.project = new Project(properties.project);
        this.before_status = properties.before_status;
        this.after_status = properties.after_status;
        this.before_follow_user = properties.before_follow_user;
        this.after_follow_user = properties.after_follow_user;
        this.edit_datetime = properties.edit_datetime;
        this.user = new User(properties.user);
    }

    /**
     * getter
     */
    // 変更前ステータス 表示
    get before_status_label() {
        return Status.get(this.before_status);
    }

    // 変更後ステータス 表示
    get after_status_label() {
        return Status.get(this.after_status);
    }
}

export default ProjectFile;
