import Enum from './enum'

/**
 * 取得理由
 */
class Purpose extends Enum {
    static PURPOSE_1 = 1;
    static PURPOSE_2 = 2;
    static PURPOSE_3 = 3;
    static PURPOSE_4 = 4;
    static PURPOSE_9 = 9;

    static values() {
        return {
            [this.PURPOSE_1]: '入札条件',
            [this.PURPOSE_2]: '顧客要求',
            [this.PURPOSE_3]: '社内体制の整備のため',
            [this.PURPOSE_4]: '自社ブランディング',
            [this.PURPOSE_9]: 'その他',
        }
    }
}

export default Purpose;
