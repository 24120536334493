/**
 * 案件競合トラン
 */
import { isBlank } from '@/utilities/typing';
import Project from '@/models/entities/project';
import Competitor from '@/models/entities/competitor';

class ProjectCompetitor {
    project_competitor_id;
    project;
    competitor;
    is_lost_competitor;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.project_competitor_id = properties.project_competitor_id;
        this.project = new Project(properties.project);
        this.competitor = new Competitor(properties.competitor);
        this.is_lost_competitor = properties.is_lost_competitor;
    }
}

export default ProjectCompetitor;
