import Enum from './enum'

/**
 * お客様の声 : 場面
 */
class VoiceScene extends Enum {
    static SALES = 1; // 営業時
    static FOLLOW = 2; // 追客時
    static ORDER = 3; // 受注時
    static AFTER_CONTRACT = 4; // 契約後
    static CONTACT = 5; // 反響
    static APPOINTMENT = 6; // アポ

    static values() {
        return {
            [this.SALES]: '営業時',
            [this.FOLLOW]: '追客時',
            [this.ORDER]: '受注時',
            [this.AFTER_CONTRACT]: '契約後',
            [this.CONTACT]: '反響',
            [this.APPOINTMENT]: 'アポ',
        }
    }
}

export default VoiceScene;
