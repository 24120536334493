import Enum from './enum'

/**
 * 規格
 */
class Standard extends Enum {
    static Q_NEW = 1;
    static Q_OPERATION = 2;
    static E_NEW = 3;
    static E_OPERATION = 4;
    static ISMS_NEW = 5;
    static ISMS_OPERATION = 6;
    static P_NEW = 7;
    static P_OPERATION = 8;
    static JISQ15001_NEW = 9;
    static JISQ15001_OPERATION = 10;

    static values() {
        return {
            [this.Q_NEW]: 'Q新規',
            [this.Q_OPERATION]: 'Q運用',
            [this.E_NEW]: 'E新規',
            [this.E_OPERATION]: 'E運用',
            [this.ISMS_NEW]: 'ISMS新規',
            [this.ISMS_OPERATION]: 'ISMS運用',
            [this.P_NEW]: 'P新規',
            [this.P_OPERATION]: 'P運用',
            [this.JISQ15001_NEW]: 'JISQ15001新規',
            [this.JISQ15001_OPERATION]: 'JISQ15001運用',
        }
    }
}

export default Standard;
