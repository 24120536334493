/**
 * 案件規格トラン
 */
import { isBlank } from '@/utilities/typing';
import Project from '@/models/entities/project';

class ProjectStandard {
    project_standard_id;
    project;
    standard;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.project_standard_id = properties.project_standard_id;
        this.project = new Project(properties.project);
        this.standard = properties.standard;
    }
}

export default ProjectStandard;
