import Enum from './enum'

/**
 * 契約ステータス
 */
class ContractStatus extends Enum {
    static PENDING = 1; // 契約書原案未送付
    static WAITING_FOR_REVIEW = 2; // 契約書原案送付済
    static WAITING_FOR_SIGN = 3; // クラウドサイン送付済
    static EXECUTED = 4; // 契約締結済

    static values() {
        return {
            [this.PENDING]: '契約書原案未送付',
            [this.WAITING_FOR_REVIEW]: '契約書原案送付済',
            [this.WAITING_FOR_SIGN]: 'クラウドサイン送付済',
            [this.EXECUTED]: '契約締結済',
        }
    }
}

export default ContractStatus;
